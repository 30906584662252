
import Vue from "vue";
import ProjectNewScanDialog from "@/components/project/scans/ProjectNewScanDialog.vue";
import ProjectMoveScanDialog from "@/components/project/scans/ProjectMoveScanDialog.vue";
import ProjectScanCard from "@/components/project/scans/ProjectScanCard.vue";
import ProjectNewScanCard from "@/components/project/scans/ProjectNewScanCard.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { devices, IDevice, IScan, IScansUpdateReqest, scans } from "@/api";
import { Actions, ScansActions } from "@/store/models";

export default Vue.extend({
  name: "ProjectScans",
  components: {
    ProjectNewScanDialog,
    ProjectMoveScanDialog,
    ProjectScanCard,
    ProjectNewScanCard,
    ConfirmationDialog,
  },
  props: {
    project: Object,
    areaOfInterest: Object,
  },
  data() {
    return {
      addScanDialog: false,
      deleteScanDialog: false,
      moveScanDialog: false,
      devices: [] as IDevice[],
      scans: [] as IScan[],
      selectedScans: new Set<number>(),
    };
  },
  computed: {
    companyId: function (): number {
      return Number(this.$store.state.company.id);
    },
    isLocked: function (): boolean {
      return this.project.status === 0;
    },
    scansByAreaOfInterest: function (): IScan[] {
      return this.scans.filter((s) => {
        return s.projects_areas_of_interest.id === this.areaOfInterest.id;
      });
    },
    selectedScansObject: function (): IScan[] {
      return this.scans.filter((s) => {
        return this.selectedScans.has(s.id);
      });
    },
  },
  created() {
    this.getScans();
    if (this.companyId) {
      // this.getDevices();
    }
  },
  methods: {
    // dialogs
    toggleAddScanDialog(dialog: boolean) {
      this.addScanDialog = dialog;
    },
    toggleMoveScanDialog(dialog: boolean) {
      this.moveScanDialog = dialog;
    },
    toggleDeleteScanDialog(dialog: boolean) {
      this.deleteScanDialog = dialog;
    },
    async getDevices() {
      this.devices = (await devices.getAllByCompany(this.companyId)).data;
    },
    // scans
    async getScans() {
      try {
        this.scans = (await scans.getByProject(this.project.id)).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    selectScan(id: number) {
      if (!this.selectedScans.delete(id)) {
        this.selectedScans.add(id);
      }
      this.$forceUpdate();
    },
    download() {
      if (this.selectedScans.size)
        this.$store.dispatch(ScansActions.Download, [...this.selectedScans]);
    },
    async confirmRemove() {
      if (!this.selectedScans.size) {
        return;
      }
      this.deleteScanDialog = true;
    },
    async remove() {
      await this.$store.dispatch(ScansActions.Delete, [...this.selectedScans]);
      this.getScans();
      this.selectedScans.clear();
    },
    async move(request: IScansUpdateReqest) {
      if (!this.selectedScans.size) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please select an item first."
        );
        return;
      }
      const fns = [] as any;
      this.selectedScans.forEach((id) => {
        fns.push(scans.move(id, request));
      });
      try {
        const responses: any = await Promise.allSettled(fns);
        responses.forEach((r: any) => {
          if (r.status == "rejected") {
            this.$store.dispatch(Actions.DisplayError, r.reason);
          }
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.getScans();
      }
    },
    async updateDescription(id: number, description: string) {
      try {
        await scans.update(id, description);
        this.getScans();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    goToScan(id: number) {
      this.$router.push(`/projects/${this.project.id}/scan/${id}`);
    },
  },
  watch: {
    async companyId(val) {
      if (val) {
        this.getDevices();
      }
    },
    // selectedScans: {
    //   deep: true,
    //   handler() {
    //     console.log(this.selectedScans)
    //   }
    // }
  },
});

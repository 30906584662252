
import {
  attachments,
  IDevice,
  storage,
} from "@/api";
import { Actions, Mutations } from "@/store/models";
import { byteToGB } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "ProjectAddScan",
  props: {
    dialog: Boolean,
    selectedAOI: Object,
    devices: Array,
    fileTypes: Array,
  },
  data() {
    return {
      threeDfiles: [] as File[],
      twoDfiles: [] as File[],
      scanDescription: "",
      device: {} as IDevice,
      files: [] as File[],
    };
  },
  computed: {
    companyId: function (): number {
      return Number(this.$store.state.company.id);
    },
    projectId: function (): number {
      return Number(this.$route.params.id);
    },
  },
  methods: {
    async uploadScan() {
      if (!this.files) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in required details."
        );
        return;
      }
      const { data } = await storage.getNumbers();
      const fileSize = byteToGB(
        this.files.reduce((prev, curr) => {
          return prev + curr.size;
        }, 0)
      );
      const storageLeft = data.storage_limit - data.storage_used;
      if (fileSize > storageLeft) {
        this.$store.dispatch(
          Actions.DisplayError,
          "File size exceeds storage space left."
        );
        return;
      }
      try {
        this.$store.commit(Mutations.SetForceLoading, true);
        const fns = [] as any;

        this.files.forEach((f) => {
          fns.push(
            attachments.projects.uploadScan(this.projectId, {
              data: f,
              projects_areas_of_interest_name: this.selectedAOI.name,
            })
          );
        });
        const responses: any = await Promise.allSettled(fns);
        responses.forEach((r: any) => {
          if (r.status == "rejected") {
            // this.$store.dispatch(Actions.DisplayError, r.reason);
            console.log('uploadScan error', r.reason)
          }
        });
      } catch (e) {
        // this.$store.dispatch(Actions.DisplayError, e);
        console.log('uploadScan error', e)
      } finally {
        this.$emit("handleScansChange");
        this.$emit("toggleDialog", false);
        this.clearForm();
        this.$store.commit(Mutations.SetForceLoading, false);
      }
    },
    async uploadScanold() {
      if (!this.files) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in required details."
        );
        return;
      }
      //let scanId = 0;
      try {
        this.$store.commit(Mutations.SetForceLoading, true);
        // const request = {
        //   device: {
        //     id: (this.devices[0] as IDevice).id, // TODO
        //   },
        //   project: {
        //     id: this.projectId,
        //   },
        //   scan_description: this.scanDescription!,
        //   company: {
        //     id: this.companyId,
        //   },
        //   projects_areas_of_interest: {
        //     id: this.areaOfInterestId,
        //   },
        // } as any;
        // const response = await scans.add(request);
        // scanId = response.data.id;

        const fns = [] as any;
        // this.twoDfiles.forEach((f) => {
        //   fns.push(
        //     attachments.projects.uploadAttachments(this.projectId, f)
        //   );
        // });
        // this.threeDfiles.forEach((f) => {
        //   fns.push(
        //     attachments.projects.uploadAttachments(this.projectId, f)
        //   );
        // });

        this.files.forEach((f) => {
          fns.push(
            attachments.projects.uploadScan(this.projectId, {
              data: f,
              projects_areas_of_interest_name: this.selectedAOI.name,
            })
          );
        });
        await Promise.allSettled(fns);

        // this.$emit("handleScansChange");
        // this.$emit("toggleDialog", false);
        // this.clearForm();
      } catch (e) {
        // if (scanId) {
        //   scans.delete([scanId]);
        // }
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        // setTimeout(() => {

        // })
        this.$emit("handleScansChange");
        this.$emit("toggleDialog", false);
        this.clearForm();
        this.$store.commit(Mutations.SetForceLoading, false);
      }
    },
    clearForm() {
      this.scanDescription = "";
      this.device = {} as IDevice;
      this.threeDfiles = [];
      this.twoDfiles = [];
      this.files = [];
    },
    cancel() {
      this.clearForm();
      this.$emit("toggleDialog", false);
    },
  },
  watch: {
    // async companyId(val) {
    //   if (val) {
    //     this.devices = (await devices.getAllByCompany(this.$store.state.company.id)).data
    //   }
    // }
  },
});


import { IReportHeaderTemplate, projects } from "@/api";
import { Actions, Mutations } from "@/store/models";
import { bufferToImage, getDate } from "@/utils";
import Vue from "vue";
import ReportHeaderTemplateNameDialog from "@/components/reports/report/reportContent/ReportHeaderTemplateNameDialog.vue";
import GmapAddressAutocomplete from "@/components/common/GmapAddressAutocomplete.vue";
import Target from "ol/events/Target";

export default Vue.extend({
  name: "ReportHeader",
  props: {
    item: Object,
    reportId: Number,
    disabled: Boolean,
  },
  components: {
    ReportHeaderTemplateNameDialog,
    GmapAddressAutocomplete,
  },
  data() {
    return {
      report: {} as IReportHeaderTemplate,
      edit: false,
      saveTemplateDialog: false,
    };
  },
  computed: {
    lastUpdated: function (): string {
      return getDate(
        this.report.updated_datetime || this.report.created_datetime
      );
    },
    logo: function (): string {
      const icon = this.report.company_icon || ({} as any);
      return icon.data ? bufferToImage(icon.data) : "";
    },
    isPublic: function (): boolean {
      return this.$route.path.toLowerCase().startsWith("/public");
    },
  },
  methods: {
    toggleSaveTemplateDialog(val: boolean) {
      this.saveTemplateDialog = val;
    },
    async save() {
      try {
        await projects.reports.update(this.reportId, {
          report_header_json: { ...this.report, company_icon: undefined },
        } as any);
        this.edit = false;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async saveAsTemplate(name: string) {
      try {
        await projects.reportHeaderTemplates.add({
          ...this.report,
          name,
          company_icon: undefined,
          id: undefined,
        } as any);
        this.report = this.item;
        this.edit = false;
        this.$store.commit(Mutations.SetToast, {
          text: "Template saved successfully.",
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    openWebsite() {
      let url = this.report.domain_name;
      if (!url?.startsWith("http")) {
        url = "https://" + url;
      }
      window.open(url, "_blank");
    },
    openAddress() {
      window.open(
        "https://www.google.com/maps/dir/" + this.report.address,
        "_blank"
      );
    },
    openTel() {
      window.open(`tel:${this.report.phone}`, "_self");
    },
    openMail() {
      window.open(`mailto:${this.report.email}`, "_self");
    },
  },

  watch: {
    item: {
      deep: true,
      immediate: true,
      handler() {
        this.report = JSON.parse(JSON.stringify(this.item)) || {};
      },
    },
  },
});

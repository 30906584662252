
import EditableLabel from "@/components/common/EditableLabel.vue";
import Vue from "vue";
import { sizeInMB } from "@/utils";

export default Vue.extend({
  name: "ScanCard",
  components: {
    EditableLabel,
  },
  props: {
    scan: Object,
    isLocked: Boolean,
  },
  data() {
    return {
      isReportView: this.$route.path.toLowerCase().includes("/report/")
    };
  },
  computed: {
    size: function (): string {
      return sizeInMB(this.scan.size);
    },
  },

  methods: {
    updateDescription(val: string) {
      this.$emit("updateDescription", this.scan.id, val);
    },
    goToScan() {
      if (!this.isReportView) {
        this.$emit('getScan', this.scan.id)
      }
    }
  },
});

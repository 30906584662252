
import Vue from "vue";

export default Vue.extend({
  name: "ReportHeaderTemplateNameDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      name: ''
    }
  },
  methods: {
    async onConfirmation() {
      if (!this.name) return
      await this.$emit("onConfirmation", this.name);
      this.$emit("toggleDialog", false);
    },
  },
  watch: {},
});

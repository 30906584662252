export class Broadcast {
  private static instance: BroadcastChannel;

  private constructor() {
    Broadcast.instance = new BroadcastChannel("gssi_channel")
  }

  public static getInstance(): BroadcastChannel {
    if (!Broadcast.instance) {
      Broadcast.instance = new BroadcastChannel("gssi_channel")
    }
    return Broadcast.instance;
  }
}

export enum BroadcaseMessage {
  PdfGenerated = "PdfGenerated"
}


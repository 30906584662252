
import { IProject, IProjectAreaOfInterest, projects, scans } from "@/api";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  components: { ConfirmationDialog },
  name: "ProjectMoveScanDialog",
  props: {
    dialog: Boolean,
    selectedScans: Array,
  },
  data() {
    return {
      project: {} as IProject,
      areasOfInterest: [] as IProjectAreaOfInterest[],
      areaOfInterest: {} as IProjectAreaOfInterest,
      duplicateTitleDialog: false,
    };
  },
  computed: {
    companyId: function (): number {
      return Number(this.$store.state.company.id);
    },
    projectId: function (): number {
      return Number(this.$route.params.id);
    },
    projects: function (): IProject[] {
      return this.$store.state.company.projects || [];
      // .filter(
      //   (p: IProject) => {
      //     return p.id !== this.projectId && p.status === 1;
      //   }
      // );
    },
  },
  methods: {
    async precheck() {
      if (!this.project.id || !this.areaOfInterest.id) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in required details."
        );
        return;
      }

      try {

        // const destScans = (
        //   await scans.getByProject(this.project.id)
        // ).data.filter(
        //   (s) => s.projects_areas_of_interest.id === this.areaOfInterest.id
        // );
        // console.log(destScans)
        // console.log(this.selectedScans)

        // for (let s of this.selectedScans) {
        //   if (destScans.find((ds) => ds.scan_name === (s as any).scan_name)) {
        //     this.duplicateTitleDialog = true;
        //     return;
        //   }
        // }


        this.move();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async move() {
      try {
        const request = {
          project: { id: this.project.id },
          projects_areas_of_interest: { id: this.areaOfInterest.id },
        };

        this.$emit("move", request);
        this.$emit("toggleDialog", false);
        this.project = {} as IProject;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
  watch: {
    async project(val) {
      if (val.id) {
        this.areasOfInterest = (
          await projects.areaOfInterests.getAll(val.id)
        ).data;
      } else {
        this.areasOfInterest = [];
      }
    },
  },
});


import Vue from "vue";
import { attachments, IAttachment } from "@/api";
import { Actions } from "@/store/models";
// import ReportAttachmentCard from "./ReportAttachmentCard.vue";
import ProjectAttachmentCard from "@/components/project/attachments/ProjectAttachmentCard.vue";

export default Vue.extend({
  name: "ReportAttachments",
  components: {
    // ReportAttachmentCard,
    ProjectAttachmentCard,
  },
  props: {
    project: Object,
    selectAOI: Object,
    isPdfView: Boolean,
  },
  data() {
    return {
      items: [] as IAttachment[],
    };
  },
  computed: {
    companyId: function (): number {
      return Number(this.$store.state.company.id);
    },
    isLocked: function (): boolean {
      return this.project.status === 0;
    },
    resultsByAreaOfInterest: function (): IAttachment[] {
      return this.items;
    },
  },
  created() {
    this.getAll();
  },
  methods: {
    async getAll() {
      if (!this.project?.id || !this.selectAOI?.id) {
        return;
      }
      try {
        this.items = (
          await attachments.projects.getAll(this.project.id, this.selectAOI.id)
        ).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
  watch: {
    selectAOI: {
      deep: true,
      handler() {
        this.getAll();
      },
    },
  },
});
